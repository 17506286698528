import { Pane } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getClients } from "../../api/clients";
import { getInvoice } from "../../api/invoices";
import { getOwners } from "../../api/owners";
import Layout from "../../layouts";
import { OwnerModal, ClientModal } from "../../modals";
import InvoiceForm from "./InvoiceForm";
import InvoicePreview from "./InvoicePreview";

const Container = styled(Pane)`
    display: flex;
    flex-direction: row;
    margin: 10px;
    flex-grow: 1;
    width: 100%;
`
// const owners = [
//     { label: 'Coetic X', value: 125 },
//     { label: 'Invoice Maker', value: 32 },
// ]
// const clients = [
//     { label: 'Client A', value: 271 },
//     { label: 'Client B', value: 321 },
//     { label: 'Client C', value: 322 },
//     { label: 'Client D', value: 323 },
//     { label: 'Client E', value: 324 },
//     { label: 'Client F', value: 325 },
//     { label: 'Client G', value: 326 },
// ]
const currencies = [
    {
      value: "USD",
      label: "USD",
    },
    {
      value: "EUR",
      label: "EUR",
    },
    {
      value: "GBP",
      label: "GBP",
    },
];
const EditInvoice = () => {
    const {id} = useParams()
    const [invoice, setInvoice] = useState();
    const [owners, setOwners] = useState([])
    const [clients, setClients] = useState([])
    
    const [isOwnerModalShown, setIsOwnerModalShown] = React.useState(false)
    const [isClientModalShown, setIsClientModalShown] = React.useState(false)
    const useFormContext = useForm(
        {
        defaultValues: {
            // tenant: "",
        //     owner: String(owners?.[0]?._id),
        //     client: String(clients?.[0]?._id),

            invoiceDate: new Date(),
            dueDate: new Date(),
        //     title: null, description: null, quantity: null, rate: null,
            invoiceItems: [],
        //     currency: "USD",
        //     tvsh: false
        }
        }
    );
    
    useEffect(()=>{
        getInvoice(id).then(({data})=> {
            setInvoice(data)
            Object.entries(data).map(([key, value])=> {
                if(key === 'invoiceDate' || key === 'dueDate'){
                    useFormContext.setValue(key, new Date(value));
                } else {
                    useFormContext.setValue(key, value);
                }
                

            })
        });
        getOwners().then(({data})=> {
            setOwners(data)
            // useFormContext.setValue("owner", String(data?.[0]?._id));
        });
        getClients().then(({data})=> {
            setClients(data)
            // useFormContext.setValue("client", String(data?.[0]?._id));
        });
    },[])
    if(!invoice) return null;
    // console.log(owners, {ownerId: String(owners?.[0]?._id)});
    // console.log(clients, {ownerId: String(clients?.[0]?._id)});
    // console.log(useFormContext.watch("client"));

    return (
        <Layout hideNav={true}>
            <Container>
                <InvoiceForm 
                    useFormContext={useFormContext} 
                    owners={owners} 
                    clients={clients} 
                    currencies={currencies}
                    openCreateOwnerModal={()=>setIsOwnerModalShown(true)}    
                    openCreateClientModal={()=>setIsClientModalShown(true)}   
                />
                <InvoicePreview 
                    owners={owners} 
                    clients={clients} 
                    watch={useFormContext.watch} 
                />
            </Container>

            <OwnerModal isShown={isOwnerModalShown} setIsShown={setIsOwnerModalShown} /> 
            <ClientModal isShown={isClientModalShown} setIsShown={setIsClientModalShown} /> 
        </Layout>
    )
}

export default EditInvoice;