import { Pane } from "evergreen-ui";
import React from "react";
import styled from "styled-components";
import Invoice from "../../Invoice/Invoice";

const InvoiceWrapper = styled(Pane)`
    width: 45%;
    margin: -100px 6% 0;
    border: 1px solid rgba(0,0,0,0.08);
    height: 100%;
    transform: scale(0.7);

`
// width: "45%",
// margin: "0 6%",
// 

const InvoicePreview = ({watch, owners, clients}) => {
    
      const owner = watch("owner")
      const client = watch("client")
      const invoiceItems = watch("invoiceItems")
      const invoiceNumber = watch("invoiceId")
      const invoiceDate = watch("invoiceDate")
      const dueDate = watch("dueDate")
      const currency = watch("currency")
      const tvsh = watch("tvsh")
      const vat = watch("vat")
      
    return (
        <InvoiceWrapper>
            <Invoice
                owner={owners.find(_owner=>owner === _owner._id)}
                client={clients.find(_client=>client === _client._id)}
                invoice={{ invoiceNumber, invoiceDate, dueDate, currency, tvsh, vat }}
                invoiceItems={invoiceItems}
            />
            <Invoice
                print={true}
                owner={owners.find(_owner=>owner === _owner._id)}
                client={clients.find(_client=>client === _client._id)}
                invoice={{ invoiceNumber, invoiceDate, dueDate, currency, tvsh, vat }}
                invoiceItems={invoiceItems}
            />
        </InvoiceWrapper>
    )
}

export default InvoicePreview