import { Button } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { getProfile, patchUser } from "../../api/users";
import { Form, Input, SubmitButton, Message as Msg } from "../../components/Form";
import { Title } from "../../components/Title";
// import { getOwners } from "../../api/owners";
// import { Message as Msg } from "../../components/Form";
// import { Title } from "../../components/Title";
import Layout from "../../layouts";
// import { OwnerModal } from "../../modals";

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-grow:1;
    width: 100%;
    // margin:20px;
`

const Header = styled(Title)`
    margin: 10px 0 30px;
`

const Message = styled(Msg)`
    margin: 10px 0;
    text-align: left;
    font-size:14px;
`
const Spacer = styled.div`
    margin: 6px 0;
    ${({ noBorder }) => noBorder ? '' : 'border-bottom: 1px solid #1687A7;'}
    opacity: 0.05;
`
const Profile = () => {
    const [user, setUser] = useState()
    const [showEdit, setShowEdit] = useState(false);
    const { register, handleSubmit, watch, setValue, getValues, setError, formState: { errors } } = useForm({ defaultValues: { _id: null, username: "", fullName: "", email: "" } });

    useEffect(() => {
        getProfile().then(({ data }) => {
            setUser(data)
            const { _id, username, fullName, email } = data;
            setValue("_id", _id)
            setValue("username", username)
            setValue("fullName", fullName)
            setValue("email", email)
        });
    }, [])

    if (!user) return null;

    const onSubmit = async data => {
        try {
            const response = await patchUser(data._id, data);

            const { _id, username, fullName, email } = response.data;
            
            setValue("_id", _id)
            setValue("username", username)
            setValue("fullName", fullName)
            setValue("email", email)
            setUser(response.data);
            setShowEdit(false)
        } catch (err) {
            if(err.response.status === 409){
                const {data} = err?.response || {};
                if(data?.code === 11000){
                    Object.keys(data?.keyPattern).map(key=>{
                        console.log(key);
                        setError(key, { invalid: true, message: key[0].toUpperCase()+key.slice(1)+" already taken" })
                    })
                }

            }
            console.error(err);
        }
    }
    console.log(errors?.username?.invalid)


    return (
        <Layout>
            <Container>
                {Boolean(user) && (
                    <>
                        {!showEdit ?(
                             <Form>
                            <Header>Profile</Header>
                            <Spacer noBorder />
                            {user.fullName && <Message>Full Name: <span>{user.fullName}</span></Message>}
                            {user.email && <Message>Email: <span>{user.email}</span></Message>}
                            {user.username && <Message>Username: <span>{user.username}</span></Message>}
                            <br/>
                            <SubmitButton onClick={()=>setShowEdit(true)}>Update</SubmitButton>
                        </Form>
                        ):(
                        <Form is="form" onSubmit={handleSubmit(onSubmit)}>
                            <Title>Edit Profile</Title>
                            <Input
                                placeholder="Full Name"
                                {...register("fullName")}
                            />
                            <Input
                                placeholder="E-mail"
                                {...register("email")}
                            />
                            <Input
                                placeholder="Username"
                                {...register("username")}
                                isInvalid={errors?.username?.invalid}
                                validationMessage={errors?.username?.message}
                            />
                            <SubmitButton>Save</SubmitButton>
                            <Spacer noBorder />
                            <Button appearance="minimal" onClick={()=>setShowEdit(false)}>Back</Button>
                        </Form>
                        )}
                    </>
                )}
            </Container>
        </Layout>
    )
}

export default Profile