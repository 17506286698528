
export const convertCurrency = (from, to, amount, conversionRates) => {
    console.log({from, to, amount, conversionRates})
    let newAmount = amount;
    if (from === "EUR" && to === "USD")
      newAmount = newAmount / conversionRates.eur;
    if (from === "GBP" && to === "USD")
      newAmount = newAmount / conversionRates.gbp;
    // If Active currency EUR/GBP - convert to EUR/GBP
    if (from === "USD" && to === "EUR")
      newAmount = newAmount * conversionRates.eur;
    if (from === "USD" && to === "GBP")
      newAmount = newAmount * conversionRates.gbp;
  
    if (from === "EUR" && to === "GBP")
      newAmount = (newAmount / conversionRates.eur) * conversionRates.gbp;
  
    return newAmount;
  };