import { Button, Pane, SearchInput, TextInputField } from "evergreen-ui"
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { login } from "../api/users";
import { Form, Input, Message, SubmitButton } from "../components/Form";
import { Title } from "../components/Title";


const Login = () => {
    const history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if(!email && !password) return;
        try {
            await login({ email, password });
            history.push("/");
        } catch (err) {
            const message = err?.response?.data?.message;
            console.log(JSON.stringify(err.response?.data?.message));
            if(message === "Confirm your email first") history.push("/verify", {email})
        }
    }

    return (
        <Pane minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
            <Form is="form" onSubmit={handleSubmit}>
                    <Title>Invoice Generator</Title>
                    <Input
                        placeholder="E-mail"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        isInvalid={isSubmitted && !email}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        isInvalid={isSubmitted && !password}
                    />
                    <SubmitButton type="primary">Login</SubmitButton>
                <Message>Not registered? <a href="/signup">Create an account</a></Message>
            </Form>
        </Pane>
    )
}
export default Login