import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getClients } from "../../api/clients";
import { Message as Msg } from "../../components/Form";
import { Title } from "../../components/Title";
import Layout from "../../layouts";
import { ClientModal } from "../../modals";

const Box = styled.div`
    // background: #E6E6E6;
    background: #fdfdfd;
    border: 1px solid #1687a733;
    padding: 20px 40px;
    margin: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: all .2s;
    &:hover{
        transform: scale(1.01);
    }
`
const Header = styled(Title)`
    margin: 10px 0;
`
const Message = styled(Msg)`
    margin: 0;
`
const Spacer = styled.div`
    margin: 6px 0;
    ${({noBorder})=> noBorder? '':'border-bottom: 1px solid #1687A7;'}
    opacity: 0.05;
`

const Client = ({setSelectedClient, _id, company, address1, address2,email,website, phone, firstName, middleName,lastName, taxNumber,vatNumber, vatDescription}) => {
    return (
        <Box onClick={setSelectedClient}>
            <Header>{company}</Header>
            <Spacer noBorder/>
            {/* <Message>Company: <span>{company}</span></Message> */}
            {address1 && <Message>Address: <span>{address1}</span></Message>}
            {address2 && <Message>City, Country: <span>{address2}</span></Message>}
            {Boolean(email || website || phone) ? (<Spacer/>) : (<br/>)}
            {email && <Message>E-mail: <span>{email}</span></Message>}
            {website && <Message>Website: <span>{website}</span></Message>}
            {phone && <Message>Phone: <span>{phone}</span></Message>}
            {Boolean(firstName || middleName || lastName) ? (<Spacer/>) : (<br/>)}
            {firstName && <Message>First Name: <span>{firstName}</span></Message>}
            {middleName && <Message>Middle Name: <span>{middleName}</span></Message>}
            {lastName && <Message>Last Name: <span>{lastName}</span></Message>}
            {Boolean(taxNumber || vatNumber || vatDescription) ? (<Spacer/>) : (<br/>)}
            {taxNumber && <Message>TIN / Tax Number: <span>{taxNumber}</span></Message>}
            {vatNumber && <Message>Vat Number: <span>{vatNumber}</span></Message>}
            {vatDescription && <Message>Vat Description: <span>{vatDescription}</span></Message>}
            <Spacer noBorder/>
        </Box>
    )
}

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
`
const NewClient = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    p,span{
        color: #1687A7;
    }
    span{
        font-size: 24px;
    }
    p{
        font-size: 16px;
    }
`

const Clients = () => {
    const [clients, setClients] = useState([])
    const [isClientModalShown, setIsClientModalShown] = React.useState(false)
    const [selectedClient, setSelectedClient] = useState()

    useEffect(()=>{
        getClients().then(({data})=> setClients(data));
    },[])
    useEffect(()=>{
        getClients().then(({data})=> setClients(data));
    },[isClientModalShown])


    return (        
        <Layout>
            <Container>
                {clients.map(client=>(
                    <Client 
                        {...client} 
                        setSelectedClient={()=>{
                            setSelectedClient(client)
                            setIsClientModalShown(true);
                        }}  
                    />
                ))}
                <NewClient onClick={()=>{
                    setSelectedClient()
                    setIsClientModalShown(true)
                }}>
                    <span>+</span>
                    <p>Create a new client</p>
                </NewClient>
            </Container>
            {isClientModalShown && (<ClientModal defaultValues={selectedClient} isShown={isClientModalShown} setIsShown={setIsClientModalShown} /> )}
        </Layout>
    )
}

export default Clients