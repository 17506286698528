// import { SegmentedControl } from "evergreen-ui";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { Form, SubmitButton } from "../../components/Form";
import DatePicker from "react-datepicker";
import "../../App.css";
import { Button, Checkbox, CircleIcon, SegmentedControl, TextInput } from "evergreen-ui";
import InvoiceTable from "../../Invoice/_Table";
import { patchInvoice, postInvoice } from "../../api/invoices";
// import currencies from "../../data/currencies";

const FormWrapper = styled(Form)`
    width: 41%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border: 1px solid rgba(0,0,0,0.08);
    margin-left: 6%;
    background: #fdfdfd;
    min-width: 500px;
`

const BoxWrapper = styled.div`
    display: flex;
    margin: 10px;
    width: 50%;
    .react-datepicker-wrapper input{
        width: max-content;
    }
`
const SecondaryBoxWrapper = styled.div`
    display: flex;
    margin: 10px;
`

const MainTitle = styled.div`
    min-width: 100px;
    height: 32px;
    line-height: 32px;
    text-align: left;
`

const MainInputStyled = styled(TextInput)`
    margin-right: 10px;
    width: 160px;
`
const SecondaryInputStyled = styled(TextInput)`
    margin-right: 10px;
    width: 90px;
`
const TertiaryInputStyled = styled(TextInput)`
    width: 100;
    margin:0 10px;
`
const ConfirmButton = styled(SubmitButton)`
    margin-top:15px;
    // margin-left: auto;
    // width: 100px;
`

Object.defineProperty(Array.prototype, 'chunk', {
    value: function (chunkSize) {
        var R = [];
        for (var i = 0; i < this.length; i += chunkSize)
            R.push(this.slice(i, i + chunkSize));
        return R;
    }
});



const InvoiceForm = ({useFormContext, owners, clients, currencies, openCreateOwnerModal, openCreateClientModal}) => {
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, watch, setValue,getValues, formState: { errors } } = useFormContext;
    const onSubmit = async data => {
        setLoading(true);
        console.log(data)
        try{
            if(data._id){
                data.invoiceItems.forEach(invoiceItem=> {
                   invoiceItem.quantity = +invoiceItem.quantity;
                   invoiceItem.rate = +invoiceItem.rate;
                })
                await patchInvoice(data._id, data)
            } else {
                data.invoiceItems.forEach(invoiceItem=> {
                    delete invoiceItem._id;
                })
                await postInvoice(data);
            }
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.error(err);
        }
    };

    const addInvoiceItem = () => {
        const {invoiceItems, title, description, quantity, rate } = getValues();
        setValue("invoiceItems", [...invoiceItems, {_id: Math.random(), title, description, quantity, rate}]);
        setValue("title", null)
        setValue("description", null)
        setValue("quantity", null)
        setValue("rate", null)
    }

    return (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <MainTitle>Invoice Owner:</MainTitle>
            {Object.keys(owners).length ? (
                <SegmentedControl
                    size="medium"
                    options={owners.map(owner=>({value: owner._id, label: owner.company }))}
                    value={watch("owner")}
                    onChange={(val) => setValue("owner", val)}
                />
            ): (
                <Button appearance="minimal" onClick={openCreateOwnerModal}>+ Create Owner</Button>
            )}
            <hr />
            <MainTitle>Invoice Client:</MainTitle>
            {clients.length ? (
                <>
                    {clients.chunk(3).map((clientGroup, i) => (
                        <SegmentedControl
                            size="medium"
                            options={clientGroup.map(client=>({value: client._id, label: client.company }))}
                            // value={value} 
                            value={watch("client")}
                            onChange={(val) => {
                                setValue("client", val)
                                setValue("currency", val.currency)
                            }}
                        />
                    ))}
                </>
            ): (
                <Button appearance="minimal" onClick={openCreateClientModal}>+ Create Client</Button>
            )}
            <hr />

            <div style={{ justifyContent: "center" }}>
                <BoxWrapper>
                    <MainTitle>Invoice Date:</MainTitle>
                    <DatePicker
                        className="css-12rdf6g ub-w_280px ub-fnt-fam_b77syt ub-b-btm_1px-solid-transparent ub-b-lft_1px-solid-transparent ub-b-rgt_1px-solid-transparent ub-b-top_1px-solid-transparent ub-otln_iu2jf4 ub-txt-deco_none ub-bblr_4px ub-bbrr_4px ub-btlr_4px ub-btrr_4px ub-ln-ht_16px ub-fnt-sze_12px ub-color_474d66 ub-pl_12px ub-pr_12px ub-tstn_n1akt6 ub-h_32px ub-bg-clr_white ub-b-btm-clr_d8dae5 ub-b-lft-clr_d8dae5 ub-b-rgt-clr_d8dae5 ub-b-top-clr_d8dae5 ub-box-szg_border-box"
                        selected={watch('invoiceDate')}
                        onChange={(date) => setValue('invoiceDate', date)}
                    />
                </BoxWrapper>

                <BoxWrapper>
                    <MainTitle>Due Date:</MainTitle>
                    <DatePicker
                        className="css-12rdf6g ub-w_280px ub-fnt-fam_b77syt ub-b-btm_1px-solid-transparent ub-b-lft_1px-solid-transparent ub-b-rgt_1px-solid-transparent ub-b-top_1px-solid-transparent ub-otln_iu2jf4 ub-txt-deco_none ub-bblr_4px ub-bbrr_4px ub-btlr_4px ub-btrr_4px ub-ln-ht_16px ub-fnt-sze_12px ub-color_474d66 ub-pl_12px ub-pr_12px ub-tstn_n1akt6 ub-h_32px ub-bg-clr_white ub-b-btm-clr_d8dae5 ub-b-lft-clr_d8dae5 ub-b-rgt-clr_d8dae5 ub-b-top-clr_d8dae5 ub-box-szg_border-box"
                        selected={watch('dueDate')}
                        onChange={(date) => setValue('dueDate', date)}
                    />
                </BoxWrapper>
                <SecondaryBoxWrapper>
                    <MainTitle>Invoice Nr:</MainTitle>

                    <TextInput
                        type="number"
                        placeholder="Invoice Nr: "
                        {...register('invoiceId')}
                        onChange={(evt)=>setValue('invoiceId',String(Number(evt.target.value)).padStart(4, '0') )}
                        value={String(watch("invoiceId")).padStart(4, '0')}
                    />
                </SecondaryBoxWrapper>
                <hr />
                <MainTitle>Invoice Item:</MainTitle>
                <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <MainInputStyled
                            placeholder="Title"
                            {...register('title')}
                        />
                        <MainInputStyled
                            placeholder="Description"
                            {...register('description')}
                        />
                    </div>
                    <SecondaryInputStyled
                        type="number"
                        placeholder="HRS/QTY"
                        {...register('quantity')}
                        style={{}}
                    />
                    <SecondaryInputStyled
                        type="number"
                        placeholder="Rate"
                        {...register('rate')}
                    />
                    <Button onClick={addInvoiceItem}>+</Button>
                </div>
               
            <br />
            {Boolean(watch('invoiceItems').length) && (
              <InvoiceTable
                invoiceItems={watch('invoiceItems')}
                currency={watch('currency')}
                setInvoiceItems={(invoiceItems)=>setValue("invoiceItems", invoiceItems)}
              />
            )}
            <hr />
            <div style={{ display: "flex", margin: "0 10px", alignItems: "center" }}>
              <MainTitle>Currency:</MainTitle>
              {currencies.chunk(3).map(currencyGroup=>(
                <SegmentedControl
                    width={240}
                    // height={32}
                    options={currencyGroup}
                    value={watch("currency")}
                    onChange={(val) => setValue("currency", val)}
                />
              ))}
              <Checkbox
                marginTop={0}
                marginBottom={0}
                marginLeft="auto"
                label="TVSH"
                checked={watch('tvsh')}
                onChange={e => setValue('tvsh', e.target.checked)}
              />
            </div> 
            <hr />
            <BoxWrapper>
                <MainTitle>Value Added Tax:</MainTitle>
                <TertiaryInputStyled
                    type="number"
                    placeholder="VAT"
                    {...register('vat')}
                />
            </BoxWrapper>
            <ConfirmButton type="submit" onClick={handleSubmit(onSubmit)} disabled={loading}> {loading ?<CircleIcon/> :'Save'} </ConfirmButton>
            </div>
            <br />
        </FormWrapper>
    )
}

export default InvoiceForm