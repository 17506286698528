import { Button, Dialog, InfoSignIcon, InlineAlert, Pane, Tooltip } from "evergreen-ui";
import React from "react";
import { Input, SubmitButton } from "../components/Form";
import styled from "styled-components";
import { Title } from "../components/Title";
import { useForm } from "react-hook-form";
import { deleteOwner, patchOwner, postOwner } from "../api/owners";

const RemoveFocusButton = styled.button`
    visibility:hidden;
    opacity:0;
`
const Form = styled.form`
    padding: 15px 60px 45px;
`
const TooltipCard = styled.div`
    // width:500px;
    >h1{
        font-size:16px;
        font-weight:500;
        margin-bottom:15px;
    }
    >ul {
        li{
            span{
                color: #1687A7;
            }
            padding-left:10px;
            list-style-type:none;
            &,span{
                font-size:12px;
                font-family: "Roboto", sans-serif;
            }
            
            // color: #1687A7;
        }
    }

`
const defautlValuesData = {
    logo: null,
    company: "",
    address1: "",
    address2: "",
    taxNumber: "",
    email: "",
    website: "",
    firstName: "",
    middleName: null,
    lastName: "",
    phone: "",
    vatDescription: "",
    vatNumber: "",
}

export const OwnerModal = ({ isShown, setIsShown, defaultValues = defautlValuesData }) => {
    const { register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm({ defaultValues });

    const onSubmit = async (owner) => {
        if (defaultValues._id) {
            try {
                const { data } = await patchOwner(defaultValues._id, owner);
                setIsShown(false);
            } catch (err) {
                console.error(err);
            }
        } else {
            try {
                const { data } = await postOwner(owner);
                setIsShown(false);
            } catch (err) {
                console.error(err);
            }
        }
    }
    const handleDelete = async () => {
        try {
            await deleteOwner(defaultValues._id);
            setIsShown(false);
        } catch (err) {
            console.error(err);
        }
    }
    // const [isShown, setIsShown] = React.useState(false)

    return (
        <Dialog
            containerProps={{ className: 'modal-v1' }}
            hasHeader={false}
            hasFooter={false}
            isShown={isShown}
            onCloseComplete={() => setIsShown(false)}

            preventBodyScrolling={true}
        >
            <RemoveFocusButton />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Title>{defaultValues._id ? 'Edit' : 'Create'} Owner</Title>
                <Input
                    placeholder="Company Name"
                    {...register('company')}
                />
                <Pane position="relative">
                    <Tooltip appearance="card" content={
                        <TooltipCard>
                            <Title>Upload your image in a free image hosting page</Title>
                            <strong>ex. </strong>
                            <ul>
                                <li>
                                    <span>Step 1</span>
                                    <Pane paddingLeft={10}>Go to - https://imgbb.com/</Pane>
                                </li>
                                <li>
                                    <span>Step 2</span>
                                    <Pane paddingLeft={10}>Upload Image</Pane>
                                </li>
                                <li>
                                    <span>Step 3</span>
                                    <Pane paddingLeft={10}>Select HTML Full Linked</Pane>
                                </li>
                                <li>
                                    <span>Step 4 </span><br />
                                    <Pane paddingLeft={10}>Copy the url containing <span>.png</span> <br /><i>https://i.ibb.co/NxFXy1j/name.png</i></Pane>
                                </li>
                            </ul>
                            <InlineAlert paddingLeft={10} intent="warning" marginY={16} hasIcon={false}>
                                <Pane fontSize={10}>
                                    {/* Warning:<br/> */}
                                    *Using invalid image urls may not generate Pdf correctly</Pane>
                            </InlineAlert>
                        </TooltipCard>
                    }>
                        <InfoSignIcon position="absolute" right={10} top={23} />
                    </Tooltip>
                    <Input
                        placeholder="Logo Url"
                        {...register('logo')}
                    />
                </Pane>

                <Input
                    placeholder="Address"
                    {...register('address1')}
                />
                <Input
                    placeholder="City Postal Code, Country"
                    {...register('address2')}
                />
                <Input
                    placeholder="TIN / Tax Number"
                    {...register('taxNumber')}
                />
                <Input
                    placeholder="E-mail"
                    {...register('email')}
                />
                <Input
                    placeholder="Website"
                    {...register('website')}
                />
                <Input
                    placeholder="First Name"
                    {...register('firstName')}
                />
                <Input
                    placeholder="Middle Name"
                    {...register('middleName')}
                />
                <Input
                    placeholder="Last Name"
                    {...register('lastName')}
                />
                <Input
                    placeholder="Phone"
                    {...register('phone')}
                />
                <Input
                    placeholder="Vat Description"
                    {...register('vatDescription')}
                />
                <Input
                    placeholder="Vat Number"
                    {...register('vatNumber')}
                />
                <SubmitButton type="submit">{defaultValues._id ? 'Edit' : 'Create'}</SubmitButton>
                {defaultValues._id && (
                    <Button onClick={handleDelete} cursor="pointer" appearance="minimal" intent="danger" type="button"> Delete </Button>
                )}
            </Form>
        </Dialog>
    )
}