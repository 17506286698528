import { Pane } from "evergreen-ui";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { getInvoices } from "../api/invoices";
import Layout from "../layouts";
import { OwnerModal, ClientModal } from "../modals";
import styled from "styled-components";
import { convertCurrency } from "../utils/convertCurrency";
import { Title } from "../components/Title";
import {Invoice} from "./invoices";

const LegendStyled = styled.div`
    display:flex;
    flex-direction:column;
    background-color: #f5f5f5;
    border: 1px solid #d5d5d5;
    border-radius: 3px; 
    line-height: 40px;
    padding: 0 10px;
`

const LegendItem = styled.div`
    display: flex;
    align-items: center;
    color: ${({ color }) => color};


    &:before{
        content: " ";
        width:14px;
        height:14px;
        background-color: ${({ color }) => color};
        margin-right:5px;
    }
`

const InvoiceWrapper = styled.div`
margin:12px;
    >h1{
        // color:#E6E6E6;
        margin-bottom:0;
    }
`

const InvoicesContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
`

const Index = () => {
    const [currency, setCurrency] = useState("USD")
    const [conversionRates, setConversionRates] = useState({});
    const fetchConversionRates = async () => {
        fetch(
            "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json"
        )
            .then((res) => res.json())
            .then((response) => {
                setConversionRates(response);
            })
            .catch((error) => {
                setConversionRates(null)
            });
    };

    const [isOwnerModalShown, setIsOwnerModalShown] = React.useState(false)
    const [isClientModalShown, setIsClientModalShown] = React.useState(false)

    const [invoices, setInvoices] = useState([])
    const [totals, setTotals] = useState({
        draft: 0,
        paid: 0,
        unpaid: 0,
        overdue: 0,
        total: 0,
        loaded: false
    })

    useEffect(() => {
        fetchConversionRates();

    }, [])
    useEffect(() => {
        if (conversionRates.usd) {
            getInvoices().then(({ data }) => {
                console.log("conversionRates", conversionRates)

                const _data = data.map(invoice => {
                    if (invoice.currency === currency) return { ...invoice, totalInUSD: invoice.total }
                    const totalInUSD = convertCurrency(invoice.currency, currency, invoice.total, conversionRates.usd);
                    return { ...invoice, totalInUSD };
                })
                setInvoices(_data)

                const draft = _data.filter(i => i.status === "DRAFT").map(i => i.totalInUSD).reduce((a, b) => a + b, 0);
                const paid = _data.filter(i => i.status === "PAID").map(i => i.totalInUSD).reduce((a, b) => a + b, 0);
                const unpaid = _data.filter(i => i.status == "UNPAID" && moment(i.dueDate).isAfter(moment())).map(i => i.totalInUSD).reduce((a, b) => a + b, 0);
                const overdue = _data.filter(i => i.status == "UNPAID" && moment(i.dueDate).isBefore(moment())).map(i => i.totalInUSD).reduce((a, b) => a + b, 0);
                const total = paid + unpaid + overdue;
                setTotals({ paid: Math.round(paid), unpaid: Math.round(unpaid), draft: Math.round(draft), overdue: Math.round(overdue), total: Math.round(total), loaded: true })

            });
        }

    }, [conversionRates])

    const data = [
        {
            name: "Paid",
            value: totals.paid,
            color: "#139d1ab3"
        },
        {
            name: "Not Paid",
            value: totals.unpaid,
            color: "#ae8800d6"
        },
        {
            name: "Overdue",
            value: totals.overdue,
            color: "#c32323b3"
        }
    ]

    const totalsInCurrencies = [
        {
            name: "USD",
            value: invoices.filter(invoice=> invoice.currency === "USD").map(i => i.total).reduce((a, b) => a + b, 0),
            color: "#01b696"
        },
        {
            name: "EUR",
            value: invoices.filter(invoice=> invoice.currency === "EUR").map(i => i.total).reduce((a, b) => a + b, 0),
            color: "#0c83a6"
        },
        {
            name: "GBP",
            value: invoices.filter(invoice=> invoice.currency === "GBP").map(i => i.total).reduce((a, b) => a + b, 0),
            color: "#ac56cb"
        }
    ]
    if (!totals.loaded) return null;
    return (
        <Layout>
            <Pane display="flex">
                {/* backgroundColor="#fdfdfd" */}
            <Pane borderRadius={20} margin={12}>
                <PieChart width={500} height={300}>
                    <Pie
                        data={data}
                        cx="30%"
                        cy="88%"
                        outerRadius={100}
                        innerRadius={50}
                        startAngle={180}
                        endAngle={640}
                        dataKey="value"
                        strokeWidth={10}
                        stroke="#f5f5f5"
                        strokeLinecap="round"
                        strokeOpacity={0.5}
                    >
                        {data.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
                    </Pie>
                    <Tooltip />

                    <Legend
                        content={
                            (props) =>
                                <LegendStyled>
                                    {props.payload.map(item => (
                                        <LegendItem color={item.color}>
                                            {item.value} - {item.payload.value} {currency}
                                        </LegendItem>
                                    ))}
                                </LegendStyled>
                        }
                        layout="vertical" wrapperStyle={{ top: 88, right: 30 }}
                    />

                </PieChart>
            </Pane>

                {/* backgroundColor="#fdfdfd" */}
            <Pane borderRadius={20} margin={12}>
                <PieChart width={500} height={300}>
                    <Pie
                        data={totalsInCurrencies}
                        cx="30%"
                        cy="88%"
                        outerRadius={100}
                        innerRadius={50}
                        startAngle={180}
                        endAngle={640}
                        dataKey="value"
                        strokeWidth={10}
                        stroke="#f5f5f5"
                        strokeLinecap="round"
                        strokeOpacity={0.5}
                    >
                        {totalsInCurrencies.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)}
                    </Pie>
                    <Tooltip />

                    <Legend
                        content={
                            (props) =>
                                <LegendStyled>
                                    {props.payload.map(item => (
                                        <LegendItem color={item.color}>
                                            {item.value} - {item.payload.value} {item.payload.name}
                                        </LegendItem>
                                    ))}
                                </LegendStyled>
                        }
                        layout="vertical" wrapperStyle={{ top: 88, right: 30 }}
                    />

                </PieChart>
            </Pane>
            </Pane>

            <InvoiceWrapper>
                <Title> Not Paid Invoices </Title>
                <InvoicesContainer>

                {invoices.map(invoice=>{
                    if(invoice.status === "UNPAID" || invoice.status === "o"){
                        return (
                            <Invoice {...invoice} basic/>
                        )
                    }
                    return null;
                })}
                </InvoicesContainer>
            </InvoiceWrapper>

<InvoiceWrapper>
    <Title> Paid Invoices </Title>
    <InvoicesContainer>

    {invoices.map(invoice=>{
        if(invoice.status === "PAID"){
            return (
                <Invoice {...invoice} basic/>
            )
        }
        return null;
    })}
    </InvoicesContainer>
</InvoiceWrapper>

            <OwnerModal isShown={isOwnerModalShown} setIsShown={setIsOwnerModalShown} />
            <ClientModal isShown={isClientModalShown} setIsShown={setIsClientModalShown} />
        </Layout>
    )
}

export default Index;