import {FolderNewIcon, FolderOpenIcon, GlobeIcon, Heading, HomeIcon, Icon, Image, LogOutIcon, Pane, PeopleIcon, PersonIcon, SidebarTab, TabNavigation} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import logo from "../logo.png";
import styled from "styled-components";
import { Title } from "../components/Title";
// import HeadingSecondary from "../components/Heading/HeadingSecondary";
// import pages from "../static/pages";
// import {setActiveProject} from "../store/components/projects/projects.actions";


const HeaderLink = styled.a`
    display: flex;
    justify-content: ${({isDesktop})=> isDesktop ? "unset" : "center"};
    padding-left: ${({isDesktop})=> isDesktop ? "15px" : "0"};
`
const HeaderMenu = styled(Heading)`
    display: flex;
    justify-content: ${({isDesktop})=> isDesktop ? "unset" : "center"};
    padding-left: ${({isDesktop})=> isDesktop ? "15px" : "0"};
    margin-top: 20px;
    margin-bottom: 5px;
    text-align: left;
`
const Hr = styled.hr`
    width: 100%;
    background: #efefef;
    margin: 0;
    opacity: 0.2;
`

const Tab = styled(SidebarTab)`
    // background: rgb(22 135 167 / 80%);
    &:focus, a{
        outline:0;
        box-shadow:unset;
    }
    &:hover{
        text-decoration:none;
       
    }
`
const TabText = styled.span`
    color: #5c5c5c!important;
    font-size: 12px;
    padding-top: 1px;
`
const TitleStyled = styled(Title)`
    font-size: 20px;
    margin-bottom: 0;
`


const Layout = ({tabs, projects, setActiveProject, flexDirection = "column", alignItems = "flex-start", hideNav, children}) => {
	const {pathname} = useLocation();
    const [showNav, setShowNav] = useState(!hideNav)
	// const availableTabs = tabs.filter(tab => projects.list[projects.activeProject].pages[tab.title] || tab.title === "Settings");
	// const _projects = Object.values(projects.order).map(projectId => ({label: projects.list[projectId].name, value: projectId}));
	const mediaQuery = "screen and (min-width: 768px)";
	const mql = window.matchMedia(mediaQuery);
	const [isDesktop, setIsDesktop] = useState(mql.matches);

	useEffect(() => {
		const handleMediaChange = e => {
			setIsDesktop(e.matches);
		};
		mql.addEventListener("change", handleMediaChange);
		setIsDesktop(mql.matches);

		return () => {
			mql.removeEventListener("change", handleMediaChange);
		};
	}, [mql]);

	return (
		<Pane display="flex" height="100%" minHeight="100vh">
			<Pane background="#fdfdfd" display="flex" flexDirection="column" flexGrow={isDesktop && showNav ? 1 : 0} maxWidth="15%" minWidth={isDesktop && showNav ? 215 : 55}>
				{/* <Heading is="h5" marginBottom={32} marginTop={32} size={100}>
					Invoice Generator
				</Heading> */}

				<HeaderLink isDesktop={isDesktop && showNav} href="/" rel="noreferrer" target="_blank">
                    <TitleStyled>{isDesktop && showNav ? 'Invoice Generator': 'IG'}</TitleStyled>
					{/* {isDesktop ? <Image padding={15} src={logo} width={163} /> : <Image padding={15} src="/logo.png" width={50} />} */}
				</HeaderLink>

				<Hr />

				<TabNavigation marginBottom="auto">
					<HeaderMenu isDesktop={isDesktop && showNav} is="h5" size={100}>
						Menu
					</HeaderMenu>

					{[{
                        title:"Home",
                        icon: HomeIcon,
                        url: "/",
                    },{
                        title:"Owners",
                        icon: GlobeIcon,
                        url: "/owners",
                    },{
                        title:"Clients",
                        icon: PeopleIcon,
                        url: "/clients",
                    },{
                        title:"Create Invoice",
                        icon: FolderNewIcon,
                        url: "/invoices/new",
                    },{
                        title:"Invoices",
                        icon: FolderOpenIcon,
                        url: "/invoices",
                        // disabled: true,
                    },{
                        title:"Profile",
                        icon: PersonIcon,
                        url: "/profile",
                    },{
                        title:"Logout",
                        icon: LogOutIcon,
                        url: "/logout",
                    }].map((tab, index) => (
						<Tab
							key={tab.url}
							className="minimal-dark-button"
							disabled={tab.disabled}
							// height={isDesktop && showNav ? "auto" : 50}
                            height={50}
							href={!tab.disabled ? tab.url : ""}
							// id={tab}
							is="a"
							isSelected={pathname === tab.url}
							onClick={e => (!tab.disabled  ? tab.url : e.preventDefault())}
							opacity={!tab.disabled ? 1 : 0.3}
							paddingLeft={15}
                            marginBottom={0}
							paddingY={isDesktop && showNav ? 4 : 8}
							textAlign="left"
						>
							<Pane paddingRight={9}>{tab.icon && <Icon color="rgb(22 135 167 / 80%)" icon={tab.icon} paddingLeft={isDesktop && showNav ? 0 : 2} paddingTop={8} size={ 20} />}</Pane>

							{isDesktop && showNav && <TabText>{tab.title}</TabText>}
						</Tab>
					))}
				</TabNavigation>

				
				<a href="https://coetic-x.com/" rel="noreferrer" style={{display: "flex", justifyContent: "center"}} target="_blank">
					<Image height={isDesktop && showNav ? 100 : 70} padding={isDesktop && showNav?36:0} src={logo} width={isDesktop && showNav? 'auto' : 45} style={{objectFit:"contain"}}/>

				</a>

				{/* <Pane alignItems="center" display="flex" flexDirection="column" marginBottom={16}>
					<HeadingSecondary text="Projects" />

					<Pane display="flex" justifyContent="center">
						{_projects.map(({label, value}) => (
							<Text
								key={value}
								color="#1070ca"
								cursor="pointer"
								fontSize={12}
								fontWeight={value === projects.activeProject ? 600 : 300}
								marginBottom={4}
								marginLeft={5}
								marginRight={5}
								marginTop={2}
								onClick={() => setActiveProject(value)}
								size={300}
							>
								{label}
							</Text>
						))}
					</Pane>
				</Pane> */}
			</Pane>

			<Pane alignItems={alignItems} display="flex" flex={4} flexDirection={flexDirection} overflowY="scroll" backgroundColor="rgb(235, 235, 235)" borderLeft="1px solid rgb(237, 240, 242)">
				{children}
			</Pane>
		</Pane>
	);
};

export default Layout;
