import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { deleteInvoice, getInvoices, patchInvoice } from "../../api/invoices";
import { Message as Msg, SubmitButton } from "../../components/Form";
import { Title } from "../../components/Title";
import Layout from "../../layouts";
import InvoicePreview from "../../Invoice/Invoice";
import { Button, DeleteIcon, Pane, Popover, RemoveIcon, SelectMenu, TickCircleIcon } from "evergreen-ui";
import { Link } from "react-router-dom";


const InvoiceContainer = styled(Pane)`
    display: flex;
    flex-direction: row;
    margin: 10px;
    flex-grow: 1;
    width: 1000px;
    position:fixed;
    height:1000px;
    left:0;
    right:0;
    bottom:0;
    top:0;
`

const ActionBox = styled(Pane)`
    display:flex;
    justify-content:center;
    margin-top:auto;
    button{
        margin:0 3px;
    }
`

const Box = styled.div`
    // background: #E6E6E6;
    background: #fdfdfd;
    border: 1px solid #1687a733;
    padding: 20px 40px;
    margin: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    // transition: all .2s;
    // &:hover{
    //     transform: scale(1.01);
    // }
    position:relative;
    &:hover{
        .deleteIcon {
            opacity:1;

        }
    }
    .deleteIcon {
        transition: all .1s;
        opacity:0;
        position:absolute;
        right:10px;
        top:10px;
        color: #9e0000c7;
    }
`
const Header = styled(Title)`
    margin: 10px 0;
    text-align:center;
    font-size:20px;
`
const Message = styled(Msg)`
    margin: 0;

    span[value=DRAFT]{
        color:#7a7a77b8;
    }
    span[value=UNPAID]{
        color:#ae8800d6;
    }
    span[value=OVERDUE]{
        color:#c32323b3;
    }
    span[value=PAID]{
        color:#139d1ab3;
    }

`
const Spacer = styled.div`
    margin: 6px 0;
    ${({ noBorder }) => noBorder ? '' : 'border-bottom: 1px solid #1687A7;'}
    opacity: 0.05;
`
const InvoiceItemMessage = styled(Message)`
    box-shadow: inset 0px 0px 5px 0px rgb(68 68 68 / 30%);
    padding:10px;
`
const InvoiceItem = styled.p`
    padding:5px 0;
    margin-bottom:0;
    display:flex;
    >div {
        padding: 0 4px;
    }
`

const PrintButton = styled.button`

outline: 0;
background: #1687A7;
width: 50px;
color: #fff;
border: 0;
padding: 5px 10px;
border-radius:5px;
cursor: pointer;

&:hover, &:active {
    background: #1687a7cc!important;
}
&:focus {
    background: #1687a7e6!important;
}
`
const PreviewHider = styled.div`
    visibility:hidden;
    height:0;
    overflow: hidden; 
`
const StatusButton = styled.button`
    border:0;
    background:0;
    height:unset;

`

const PopoverStyled = styled(Pane)`
width:280px;
height:180px;
display: flex;
align-items:center;
justify-content:center;
flex-direction:column;
// padding:40px;

`
const PopoverActionBox = styled(Pane)`
    // display:flex;
    margin:20px auto 0;
    
`

const statusOptions = [{
    label: "Draft",
    value: "DRAFT"
}, {
    label: "Not Paid",
    value: "UNPAID"
}, {
    label: "Paid",
    value: "PAID"
}]

export const Invoice = ({ _id, invoiceId, tenant, createdBy, owner, client, invoiceDate, dueDate, invoiceItems = [], currency, vat, status, total, basic = false }) => {
    // const [printable,setPrintable] = useState(false);
    const printRef = useRef(null);

    const handleDelete = () => {
        deleteInvoice(_id).then(() => window.location.href = window.location.href);
    }


    const handleSelect = (status) => {
        patchInvoice(_id, { status }).then(() => window.location.href = window.location.href);
    }

    return (
        <Box>
            <Header>
                Invoice #{String(invoiceId).padStart(4, '0')}
                <Popover
                    content={({ close }) => (
                        <PopoverStyled>
                            <Header>Confirm Delete</Header>
                            <Message>
                                Are you sure you want to delete this item?
                            </Message>
                            <Message>
                                <span> Invoice #{String(invoiceId).padStart(4, '0')}</span></Message>
                            <PopoverActionBox>
                                <Button appearance="minimal" onClick={close} marginLeft={4}>Cancel</Button>
                                <Button marginRight={4} appearance="primary" intent="danger" onClick={handleDelete}>Delete</Button>
                            </PopoverActionBox>

                        </PopoverStyled>
                    )}
                >
                    <RemoveIcon className="deleteIcon" marginLeft="10px" cursor="pointer" appearance="minimal" intent="danger" type="button" />
                </Popover>
            </Header>
            {!basic && (
                <>
                    <Spacer noBorder />
                    {/* <Message>Company: <span>{company}</span></Message> */}
                    {tenant && <Message>Tenant: <span>{tenant}</span></Message>}
                    {createdBy && <Message>Created By: <span>{createdBy.fullName}</span></Message>}
                    {Boolean(owner || client) ? (<Spacer />) : (<br />)}
                    {owner && <Message>Owner: <span>{owner.company}</span></Message>}
                </>
            )}
            {client && <Message>Client: <span>{client.company}</span></Message>}

            {!basic && (
                <>
                    {/* {phone && <Message>Phone: <span>{phone}</span></Message>} */}
                    {Boolean(invoiceDate || dueDate) ? (<Spacer />) : (<br />)}
                    {invoiceDate && <Message>Invoice Date: <span>{moment(invoiceDate).format("MMM Do, YYYY")}</span></Message>}
                    {dueDate && <Message>Due Date: <span>{moment(dueDate).format("MMM Do, YYYY")}</span></Message>}
                    {Boolean(invoiceItems.length) ? (<Spacer />) : (<br />)}
                    <InvoiceItemMessage>
                        Invoice Items
                        <br />
                        {invoiceItems?.map((invoiceItem, i) => (
                            <InvoiceItem>
                                <div>#{i}</div>
                                <div>
                                    <span>{invoiceItem.title} | {invoiceItem.quantity} x {invoiceItem.rate}</span><br />
                                    {invoiceItem.description}
                                </div>
                            </InvoiceItem>
                        ))}
                    </InvoiceItemMessage>
                    {Boolean(vat) ? (<Spacer />) : null}
                    {/* {taxNumber && <Message>TIN / Tax Number: <span>{taxNumber}</span></Message>} */}
                    {/* {currency && <Message>Currency: <span>{currency}</span></Message>} */}
                    {vat && <Message>Vat: <span>{vat}</span></Message>}
                    <Spacer />
                </>
            )}
            <Message>Total: <span>{total} {currency}</span></Message>
            <Spacer noBorder />


            <SelectMenu
                style={{ border: 0 }}
                title="Select name"
                options={statusOptions}
                selected={status}
                hasFilter={false}
                hasTitle={false}
                onSelect={(item) => handleSelect(item.value)}
                height='auto'
            >
                <Message role="button">
                    Status:
                    <span> </span>
                    <span value={status === "UNPAID" && moment(dueDate).isBefore(moment()) ? 'OVERDUE' : statusOptions.find(option => option.value === status).value}>
                        {status === "UNPAID" && moment(dueDate).isBefore(moment()) ? 'Overdue' : statusOptions.find(option => option.value === status).label}
                    </span>
                </Message>

            </SelectMenu>
            {!basic && (
                <>
                    <Spacer noBorder />
                    <ActionBox>
                        <Button is={Link} to={"/invoices/edit/" + _id} cursor="pointer" appearance="minimal" type="button"> Edit </Button>

                        <PrintButton type="button" onClick={() => {
                            // setPrintable(true);
                            printRef?.current?.click?.();
                        }}>Print</PrintButton>

                    </ActionBox>
                </>
            )}

            <PreviewHider
            // isActive={Boolean(printable)}
            >
                <InvoiceContainer>
                    <InvoicePreview
                        printRef={printRef}
                        owner={owner}
                        client={client}
                        invoice={{ invoiceNumber: String(invoiceId).padStart(4, '0'), invoiceDate, dueDate, currency, tvsh: "" }}
                        invoiceItems={invoiceItems}
                        print={true}
                    />
                </InvoiceContainer>
            </PreviewHider>
        </Box>
    )
}

const Container = styled.div`
    display:flex;
    flex-wrap: wrap;
`

const LinkedBox = styled(Link)`

    // background: #E6E6E6;
    background: #fdfdfd;
    border: 1px solid #1687a733;
    padding: 20px 40px;
    margin: 20px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    position:relative;
    &,&:hover{
        text-decoration:none;
    }
`

const NewInvoice = styled(LinkedBox)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor:pointer;
    
    p,span{
        color: #1687A7;
    }
    span{
        font-size: 24px;
    }
    p{
        font-size: 16px;
    }
`

const Invoices = () => {
    const [invoices, setInvoices] = useState([])

    useEffect(() => {
        getInvoices().then(({ data }) => setInvoices(data));
    }, [])

    return (
        <Layout>
            <Container>
                {invoices.map(invoice => (
                    <Invoice  {...invoice} />
                ))}
                <NewInvoice to="/invoices/new">
                    <span>+</span>
                    <p>Create a new invoice</p>
                </NewInvoice>
            </Container>
        </Layout>
    )
}

export default Invoices