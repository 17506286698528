import { Button, InlineAlert, Pane, SearchInput, TextInputField } from "evergreen-ui"
import React, { useState } from "react"
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { login, verify } from "../api/users";
import { Form, Input, Message, SubmitButton } from "../components/Form";
import { Title } from "../components/Title";


const Verified = () => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    const handleVerify = async () => {
        const params = new URLSearchParams(history.location.search)
        const token = params.get("token");

        try {
            const { data } = await verify({ token })
            setEmail(data)
        } catch (err) {
            setError(err.response)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        handleVerify();
    }, []);

    useEffect(() => {
        if (!loading && !error && !email) history.push("/login")
    }, [loading])

    return (
        <Pane minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
            <Form>
                <Title>Invoice Generator</Title>
                {email ? (
                    <p>E-mail Verified<br /><b>{email}</b></p>
                ) : loading ? (
                    <p>...</p>
                ) : error && (
                    <>
                        <InlineAlert intent="danger">
                            Email Verification went wrong!
                        </InlineAlert>
                        <br />
                        <p>Contact <b>info@coetic-x.com</b> for support!</p>
                    </>
                )}
                <br /><br />
                <SubmitButton type="primary" onClick={() => history.push("/login")}>Sign In</SubmitButton>
            </Form>
        </Pane>
    )
}
export default Verified