import axios from 'axios';
// axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL:  process.env.NODE_ENV === 'production'?`https://invoice-g.herokuapp.com/`: `http://localhost:80/`
});

// instance.defaults.headers.common['Authorization'] = "mybearer token";
export function setAuthToken(token) {
  instance.defaults.headers.common['Authorization'] = '';
  delete instance.defaults.headers.common['Authorization'];

  if (token) {
    instance.defaults.headers.common['Authorization'] = `${token}`;
  }
}
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error?.response?.status === 401 && error?.response?.data?.message !== "Confirm your email first") {
    localStorage.removeItem('token')
    window.location.href="/login";
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});
export default instance;
