import API, { setAuthToken } from ".";

export const login = async (data) => {
    return new Promise((resolve, reject) => {
        API.post("/users/login", data).then(({ data: { token } }) => {
            localStorage.setItem("token", token);
            setAuthToken("Bearer " + token)
            resolve();
        }).catch(reject)
    })
}

export const signup = (data) => API.post("/users/signup", data)

export const getProfile = () => API.get("/users/profile")

export const patchUser = (id, data) => API.patch(`/users/${id}`, data)

export const logout = async () => {
    localStorage.removeItem("token");
    setAuthToken()
    return await API.get("/users/logout")
}

export const verify = (data) => API.post("/users/verify", data)

export const resend = (data) => API.post("/users/resend", data)