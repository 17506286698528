import { Button, Pane, SearchInput, TextInputField } from "evergreen-ui"
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { login, resend } from "../api/users";
import { Form, Input, Message, SubmitButton } from "../components/Form";
import { Title } from "../components/Title";


const Verify = () => {
    const history = useHistory();
    const email = history?.location?.state?.email;

    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleResend = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        try {
            await resend({ email })
            history.push("/");
        } catch (err) {
            console.error(err);
        } finally {
            setIsSubmitted(false);
        }
    }

    return (
        <Pane minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
            <Form>
                <Title>Invoice Generator</Title>
                {email ? (
                    <p>Resend verification for<br /><b>{email}</b><br /><br /></p>
                ) : (
                    <p>No Email Provided</p>
                )}
                <SubmitButton type="primary" onClick={handleResend} disabled={isSubmitted}>{isSubmitted ? '...' : 'Resend'}</SubmitButton>
                <Message>Already confirmed? <a href="/login">Sign In</a></Message>
            </Form>
        </Pane>
    )
}
export default Verify