import { Button, Dialog, Pane } from "evergreen-ui";
import React from "react";
import { Input, SubmitButton } from "../components/Form";
import styled from "styled-components";
import { Title } from "../components/Title";
import { useForm } from "react-hook-form";
import { deleteClient, patchClient, postClient } from "../api/clients";

const RemoveFocusButton = styled.button`
    visibility:hidden;
    opacity:0;
`
const Form = styled.form`
    padding: 15px 60px 45px;
`
const defautlValuesData = {
    address1: "",
    address2: "",
    company: "",
    country: "",
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    currency: "",
}

export const ClientModal = ({ isShown, setIsShown, defaultValues = defautlValuesData }) => {
    const { register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm({defaultValues});

    const onSubmit = async(client) => {
        if(defaultValues._id){
            try{
                const {data} = await patchClient(defaultValues._id, client);
                setIsShown(false);
            } catch(err){
                console.error(err);
            }
        } else {
            try{
                const {data} = await postClient(client);
                setIsShown(false);
            } catch(err){
                console.error(err);
            }
        }
    }
    const handleDelete = async() => {
        try{
            await deleteClient(defaultValues._id);
            setIsShown(false);
        } catch(err){
            console.error(err);
        }
    }

    return (
        <Dialog
            containerProps={{ className: 'modal-v1' }}
            hasHeader={false}
            hasFooter={false}
            isShown={isShown}
            onCloseComplete={() => setIsShown(false)}

            preventBodyScrolling={true}
        >
        <RemoveFocusButton/>
        <Form onSubmit={handleSubmit(onSubmit)}>
       <Title>{defaultValues._id ? 'Edit':'Create'} Client</Title>
                <Input
                    placeholder="Company Name"
                    {...register('company')}
                />
                <Input
                    placeholder="Address"
                    {...register('address1')}
                />
                <Input
                    placeholder="City, Postal Code"
                    {...register('address2')}
                />
                <Input
                    placeholder="Country"
                    {...register('country')}
                />
                <Input
                    placeholder="E-mail"
                    {...register('email')}
                />
                <Input
                    placeholder="First Name"
                    {...register('firstName')}
                />
                <Input
                    placeholder="Middle Name"
                    {...register('middleName')}
                />
                <Input
                    placeholder="Last Name"
                    {...register('lastName')}
                />
                <Input
                    placeholder="Phone"
                    {...register('phone')}
                />
                <Input
                    placeholder="Currency"
                    {...register('currency')}
                />
            <SubmitButton type="submit">{defaultValues._id ? 'Edit':'Create'}</SubmitButton>
            {defaultValues._id && (
                <Button onClick={handleDelete} cursor="pointer" appearance="minimal" intent="danger" type="button"> Delete </Button>
            )}
            </Form>
        </Dialog>
    )
}