import { Button, Pane, TextInputField } from 'evergreen-ui'
import styled from 'styled-components'

export const Form = styled(Pane)`
    // background: #E6E6E6;
    background: #fdfdfd;
    border: 1px solid #1687a733;
    max-width: 360px;
    width:100%;
    margin: 0 auto 100px;
    padding: 45px 60px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
    div > div:first-of-type{
        margin-bottom: 2px!important;
    }
`
export const Input = styled(TextInputField)`
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    // margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius:0;
    height:46.5px;
    &[aria-invalid="true"]:not(:focus), &[aria-invalid="true"]:not(:focus){
        border-width:1px;
        border-style: solid;
    }
`
export const SearchInput = styled(Input);
export const SubmitButton = styled(Button)`
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #1687A7;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    border-radius:0;
    height:46.5px;
    &:hover, &:active {
        background: #1687a7cc!important;
    }
    &:focus {
        background: #1687a7e6!important;
    }
`

export const Message = styled.p`
    margin: 15px 0 0;
    color: #658d98cc;
    font-size: 12px;
    a,span{

        color: #1687a7e6;
        text-decoration: none;
    }
`