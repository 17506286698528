import { Button, Pane, TextInputField } from "evergreen-ui"
import React, { useState } from "react"
import { useHistory } from "react-router-dom";
import { signup } from "../api/users"
import { Form, Input, Message, SubmitButton } from "../components/Form";
import { Title } from "../components/Title";


const SignUp = () => {
    const history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (password !== confirmPassword) return;
        try {
            await signup({ email, password });
            history.push("/login");
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <Pane minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
            <Form is="form" onSubmit={handleSubmit}>
                <Title>Sign Up</Title>
                <Input
                    placeholder="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={e => {
                        setConfirmPassword(e.target.value)
                        if (password === confirmPassword) setIsSubmitted(false);
                    }}
                    isInvalid={isSubmitted && password !== confirmPassword}
                    validationMessage={isSubmitted && password !== confirmPassword ? "Your password and confirmation password do not match." : null}
                />
                <SubmitButton type="primary">Register</SubmitButton>
                <Message>Already registered? <a href="/login">Sign In</a></Message>
            </Form>
        </Pane>
    )
}
export default SignUp