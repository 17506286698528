import React from "react"
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom"
import { logout } from "./api/users"
import Home from "./pages/Home"
import Index from "./pages/Index"
import CreateInvoice from "./pages/invoices/CreateInvoice"
import Landing from "./pages/Landing"
import Login from "./pages/Login"
import SignUp from "./pages/SignUp"
import "./App.css";
import Owners from "./pages/owners"
import Profile from "./pages/profile"
import Clients from "./pages/clients"
import Invoices from "./pages/invoices"
import EditInvoice from "./pages/invoices/EditInvoice"
import Verify from "./pages/Verify"
import Verified from "./pages/Verified"

const App = () => {

  return (
    <Router>
    <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" component={Index} />
        <Route exact path="/owners" component={Owners} />
        <Route exact path="/clients" component={Clients} />
        <Route exact path="/profile" component={Profile} />

        <Route exact path="/invoices" component={Invoices} />
        <Route exact path="/invoices/new" component={CreateInvoice} />
        <Route exact path="/invoices/edit/:id" component={EditInvoice} />
        {/* <Route exact path="/invoices/new" component={CreateInvoice} /> */}



        <Route path="/verify" component={Verify}/>
        <Route path="/users/verify" component={Verified}/>
        <Route path="/login" component={Login}/>
        <Route path="/signup" component={SignUp}/>

          
        <Route exact path="/logout">
          {()=> {
            logout()
            return <Redirect to="/login" />
          }}
        </Route>
        <Route path="/landing" component={Landing}/>
        </Switch>
    </Router>
  )
}
export default App;